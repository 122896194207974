import React, { useEffect, useState } from "react";
import Sidebar from "../component/Sidebar";
import { Table } from "react-bootstrap";
import ProductService from "../services/ProductService";
import ProductModal from "../component/ProductModal";
import { Link } from "react-router-dom";
import { TableHead } from "../component/miniComponent/TableHead";
import { TableData } from "../component/miniComponent/TableData";
import { TableSkeleton } from "../component/miniComponent/TableSkeleton";
import { toast } from "react-toastify";
import { NoData } from "../component/miniComponent/NoData";
import { MdProductionQuantityLimits } from "react-icons/md";
import { FilterBox } from "../component/miniComponent/FilterBox";
import { FiDownload } from "react-icons/fi";
import ExportModal from "../component/ExportModal";

function Product() {
	const [product, setProduct] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [limit, setLimit] = useState(20);
	const [active, setActive] = useState("");
	const [loading, setLoading] = useState(false);

	const userRole = localStorage.getItem("role");
	const [exportModalShow, setExportModalShow] = useState(false);

	const listProduct = async (search, limit, active) => {
		setLoading(true);
		try {
			const products = await ProductService.getProducts(search, limit, active);

			if (products?.status) {
				setLoading(false);
				setProduct(products?.data?.value || []);
				return;
			}
			toast.error(products?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		listProduct(searchQuery, limit, active);
	}, [searchQuery, limit, active]);

	return (
		<>
			<div className="container__wrapper">
				<Sidebar />
				<div className="section__container">
					<div className="header__title">
						<h3>Product</h3>
					</div>
					<div className="top-style">
						{userRole !== "Engineering" && (
							<button
								className="action-btn"
								onClick={() => {
									setRecordToEdit(null);
									setModalShow(true);
								}}
							>
								Add Product
							</button>
						)}
						<div className="search-box">
							<input
								className="form-control"
								type="text"
								placeholder="search product"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					</div>
					<div className="top-style">
						<div className="filter-box">
							{[
								{ id: "", name: "All Status" },
								{ id: 1, name: "Active" },
								{ id: 2, name: "In active" },
							].map((x) => (
								<FilterBox
									key={x.id}
									name={x.name}
									onClick={() => setActive(x.id)}
									isActive={active === x.id}
								/>
							))}
						</div>
						<div className="">
							{userRole == "Super Admin" && <button
								className="action-btn blank"
								onClick={() => {
									setExportModalShow(true);
								}}
							>
								<FiDownload />
								<span>Export to excel</span>
							</button>}
						</div>
					</div>
					<div className={`table-responsive ${product?.length > 0 ? "" : "people-head"}`}>
						<Table>
							<thead>
								<tr className="table-head-row">
									<TableHead>Customer Name</TableHead>
									<TableHead>Product Name</TableHead>
									<TableHead>Serial Number</TableHead>
									<TableHead>Waranty Duration</TableHead>
									<TableHead>Waranty Status</TableHead>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<TableSkeleton col={5} row={5} />
								) : (
									<>
										{product?.length > 0 ? (
											<>
												{product.map((x) => {
													return (
														<tr className="tbody-row">
															<TableData>{x.customerName}</TableData>
															<TableData>{x.name}</TableData>
															<TableData>{x.serialNumber}</TableData>
															<TableData>{x.warantyDuration}</TableData>
															<TableData>{x.warantyStatus}</TableData>
															{userRole.toLocaleLowerCase() !==
																"distributor" && (
																<TableData>
																	<Link to={`/product-detail/${x.id}`}>
																		<button
																			className="table-btn"
																			onClick={() => {
																				setRecordToEdit(x);
																				setModalShow(true);
																			}}
																		>
																			View Product
																		</button>
																	</Link>
																</TableData>
															)}
														</tr>
													);
												})}
											</>
										) : (
											<NoData
												text="There is no data to show yet"
												icon={<MdProductionQuantityLimits />}
											/>
										)}
									</>
								)}
							</tbody>
						</Table>
					</div>
					<div className="d-flex justify-content-between align-items-center mt-4">
						<div className="page-count">
							Showing {product?.length || 0} items on this page
						</div>
						<button
							className="view-more"
							onClick={() => setLimit((prev) => prev + 10)}
						>
							View More
						</button>
					</div>
				</div>
			</div>
			{modalShow && (
				<ProductModal
					show={modalShow}
					onHide={() => setModalShow(false)}
					data={recordToEdit}
				/>
			)}

			{exportModalShow && (
				<ExportModal
					show={exportModalShow}
					onHide={() => setExportModalShow(false)}
					isRepair={false}
					name = "Product"
				/>
			)}
		</>
	);
}

export default Product;
