import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ProductService from "../services/ProductService";
import { PrimaryInput } from "./miniComponent/PrimaryInput";
import CustomCheckbox from "./miniComponent/CustomCheckBox";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";
import { PrimarySelect } from "./miniComponent/PrimarySelect";

function MaintainanceModal(props) {
	const [payload, setPayload] = useState(props?.data);
	const [loading, setLoading] = useState(false);
	const [collect, setCollect] = useState(props?.data?.isInstalled || false);
	const updatePayload = (data, key) => {
		const tempPayload = { ...payload };
		tempPayload[key] = data;
		setPayload(tempPayload);
	};

	let data = props.data;
	const userRole = localStorage.getItem("role");
	// console.log({ props });

	const updateData = {
		id: props?.data?.id,
		installationAccessories: props?.data?.installationAccessories,
		isInstalled: props?.data?.isInstalled,
		costOfInstallation: props?.data?.costOfInstallation,
		dateOfInstallation: props?.data?.dateOfInstallation,
		customerAddress: props?.data?.customerAddress,
		status: props?.data?.status,
		customerName: props?.data?.customerName,
		productToInstall: props?.data?.productToInstall,
		customerEmail: props?.data?.customerEmail,
		customerPhone: props?.data?.customerPhone,
		engineerId: props?.data?.engineerId,
		comment: props?.data?.comment,
	};

	const UpdateRecord = async () => {
		if (payload?.installationAccessories) {
			updateData.installationAccessories = payload.installationAccessories;
		}

		if (payload?.isInstalled) {
			updateData.isInstalled = payload?.isInstalled;
		}

		if (payload?.costOfInstallation) {
			updateData.costOfInstallation = payload?.costOfInstallation;
		}
		if (payload?.dateOfInstallation) {
			updateData.dateOfInstallation = payload?.dateOfInstallation;
		}

		if (payload?.customerAddress) {
			updateData.customerAddress = payload?.customerAddress;
		}
		if (payload?.status) {
			updateData.status = payload?.status;
		}
		if (payload?.customerName) {
			updateData.customerName = payload?.customerName;
		}
		if (payload?.productToInstall) {
			updateData.productToInstall = payload?.productToInstall;
		}
		if (payload?.customerEmail) {
			updateData.customerEmail = payload?.customerEmail;
		}
		if (payload?.customerPhone) {
			updateData.customerPhone = payload?.customerPhone;
		}
		if (payload?.comment) {
			updateData.comment = payload?.comment;
		}
		if (payload?.engineerId) {
			updateData.engineerId = payload?.engineerId;
		}
		updateData.isInstalled = collect;

		setLoading(true);
		try {
			const response = await ProductService.UpdateMaintainance(updateData);
			if (response?.status) {
				toast.success("Successfully updated");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error(response?.message);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	const CreateRecord = async () => {
		// if (!payload.pcbSerialNumber && userRole == "Engineering") {
		// 	toast.error("Pcb Serial number is Reqired");
		// 	return;
		// }
		if (!payload.customerName && userRole == "Customer Care") {
			toast.error("Customer Name is Reqired");
			return;
		}

		if (!payload.customerAddress && userRole == "Customer Care") {
			toast.error("Customer Address is Reqired");
			return;
		}

		if (!payload.customerPhone && userRole == "Customer Care") {
			toast.error("Customer Phone is Reqired");
			return;
		}
		if (!payload.customerEmail && userRole == "Customer Care") {
			toast.error("Customer Email is Reqired");
			return;
		}

		if (!payload.productToInstall && userRole == "Customer Care") {
			toast.error("Product to Install is required");
			return;
		}

		// if (!payload.dateOut && userRole == "Sales") {
		// 	toast.error("Date Out is required");
		// 	return;
		// }
		// if (!payload.status && userRole == "Sales") {
		// 	toast.error("Kindly confirm this order has been collected");
		// 	return;
		// }

		setLoading(true);
		try {
			const response = await ProductService.CreateMaintainance(payload);
			if (response?.status) {
				toast.success("Successfully created");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error("An error occured");
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props?.isEdit ? "Update Maintainance" : "Add Maintainance"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal__main_container">
					<div className="card-grid-2 mt-3">
						<PrimaryInput
							defaultValue={props?.data?.customerName}
							onChange={(e) => updatePayload(e.target.value, "customerName")}
							label="Customer Name"
							disabled={userRole !== "Customer Care" && userRole !== "Super Admin"}
						/>
						<PrimaryInput
							defaultValue={props?.data?.customerEmail}
							onChange={(e) => updatePayload(e.target.value, "customerEmail")}
							label="Customer Email"
							disabled={userRole !== "Customer Care" && userRole !== "Super Admin"}
						/>
						<PrimaryInput
							defaultValue={props?.data?.customerPhone}
							onChange={(e) => updatePayload(e.target.value, "customerPhone")}
							label="Customer Phone"
							disabled={userRole !== "Customer Care" && userRole !== "Super Admin"}
						/>
						<PrimaryInput
							defaultValue={props?.data?.customerAddress}
							onChange={(e) => updatePayload(e.target.value, "customerAddress")}
							label="Customer Address"
							disabled={userRole !== "Customer Care" && userRole !== "Super Admin"}
						/>
						{(data !== null || userRole == "Store") && (
							<PrimaryInput
								defaultValue={props?.data?.installationAccessories}
								onChange={(e) =>
									updatePayload(e.target.value, "installationAccessories")
								}
								label="Maintainance Accessories"
								disabled={
									userRole !== "Store" && userRole !== "Super Admin"
								}
							/>
						)}
						<PrimaryInput
							defaultValue={props?.data?.productToInstall}
							onChange={(e) =>
								updatePayload(e.target.value, "productToInstall")
							}
							label="Product"
							disabled={userRole !== "Customer Care" && userRole !== "Super Admin"}
						/>
						{(userRole == "Technical Manager" || userRole == "Super Admin") && (
						<PrimaryInput
							defaultValue={props?.data?.dateOfInstallation}
							onChange={(e) =>
								updatePayload(e.target.value, "dateOfInstallation")
							}
							label="Scheduled Date of Mintainance"
							type="date"
							disabled={userRole !== "Technical Manager" && userRole !== "Super Admin"}
						/>
						)}
						<PrimaryInput
							defaultValue={props?.data?.costOfInstallation}
							onChange={(e) =>
								updatePayload(e.target.value, "costOfInstallation")
							}
							label="Cost Of Mintainance"
							disabled={userRole !== "Customer Care" && userRole !== "Super Admin"}
						/>
						{(data != null && userRole == "Engineering" || userRole == "Customer Care") && (
							<PrimarySelect
								value={payload?.status}
								onChange={(e) => updatePayload(e.target.value, "status")}
								label="Mintainance status"
								options={
									<>
										{!props?.data?.status && <option>Choose a status</option>}
										{["Not Started", "Contacted", "In Progress", "Completed"].map((x) => (
											<option value={x} key={x}>
												{x}
											</option>
										))}
									</>
								}
								disabled={
									userRole !== "Engineering" && userRole !== "Super Admin" && userRole !== "Customer Care"
								}
							/>
						)}
						{userRole == "Technical Manager" && (
							<PrimarySelect
								value={payload?.engineerId}
								onChange={(e) => updatePayload(e.target.value, "engineerId")}
								label="Assign Engineer"
								options={
									<>
										{!props?.data?.engineerId && (
											<option>Choose an engineer</option>
										)}
										{props?.engineerLists?.map((x) => (
											<option value={x.id} key={x.id}>
												{x.fullName}
											</option>
										))}
									</>
								}
								disabled={
									userRole !== "Technical Manager" && userRole !== "Super Admin"
								}
							/>
						)}
					</div>
					<div className="mt-3">
						{userRole == "Engineering" && (
							<PrimaryInput
								defaultValue={payload?.comment}
								onChange={(e) => updatePayload(e.target.value, "comment")}
								label="Add a Comment"
								disabled={
									userRole !== "Engineering" && userRole !== "Super Admin"
								}
								isTextArea
							/>
						)}
					</div>
				</div>
				{data != null && userRole === "Customer Care" && (
					<div className="status mt-3">
						<CustomCheckbox
							label={"Mark as Done"}
							checked={collect}
							onChange={(e) => setCollect(!collect)}
						/>
					</div>
				)}
			</Modal.Body>
			<div className="modal-foot">
				<button onClick={props.onHide} className="btn-form outline">
					Close
				</button>
				<button
					className="btn-form fill"
					onClick={() => {
						props?.isEdit ? UpdateRecord() : CreateRecord();
					}}
				>
					{loading ? (
						<PropagateLoader color={"white"} loading size={8} />
					) : (
						"Save"
					)}
				</button>
			</div>
		</Modal>
	);
}

export default MaintainanceModal;
