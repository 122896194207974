import React from "react";
import { TableData } from "./TableData";
import Skeleton from "react-loading-skeleton";

export const TableSkeleton = ({ col, row }) => {
	return (
		<>
			{Array(col)
				.fill(col)
				.map((x) => (
					<tr className="tbody-row">
						{Array(row)
							.fill(row)
							.map((x) => (
								<TableData>
									<Skeleton count={1} height="100%" />
								</TableData>
							))}
					</tr>
				))}
		</>
	);
};
