import React from "react";

export const PrimarySelect = ({
	label,
	options,
	onChange,
	value,
	placeholder,
	disabled,
}) => {
	return (
		<div className="input-form">
			<label for="text" class="form-label labels">
				{label}
			</label>
			<select
				className="form-controls"
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				disabled={disabled}
			>
				{options}
			</select>
		</div>
	);
};
