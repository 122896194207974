import React, { useState } from "react";
import "./style/sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { MenuItem } from "./miniComponent/MenuItem";
import { RiDashboardFill } from "react-icons/ri";
import { FaPeopleRoof } from "react-icons/fa6";
import { MdLogout, MdProductionQuantityLimits } from "react-icons/md";
import { TbShoppingBagSearch } from "react-icons/tb";
import { GiAutoRepair } from "react-icons/gi";
import { GrInstall } from "react-icons/gr";
import { IoMenu } from "react-icons/io5";

function Sidebar() {
	const navigate = useNavigate();
	const userRole = localStorage.getItem("role");
	const logout = async () => {
		localStorage.clear();
		navigate("/");
	};
	const [isOpen, setIsOpen] = useState(false)
	return (
		<div className="wrapper">
			<div className={`ham ${isOpen ? 'active':''}`}><IoMenu onClick={()=> setIsOpen(prev => !prev)}/></div>
			<div className={`sidebar ${isOpen ? 'active':''}`}>
				<div className="sides">
					<div className="logo">
						<img src="/smklogo.png" alt="smk suite" />
					</div>
					{userRole == "Super Admin" && (
						<>
							<MenuItem
								url="/dashboard"
								name="Dashboard"
								icon={<RiDashboardFill />}
							/>
							<MenuItem url="/people" name="People" icon={<FaPeopleRoof />} />
							<MenuItem
								url="/product"
								name="Products"
								icon={<MdProductionQuantityLimits />}
							/>
							<MenuItem url="/repairs" name="Repairs" icon={<GiAutoRepair />} />
						</>
					)}
					{userRole == "Store" && (
						<>
							<MenuItem
								url="/product-detail"
								name="Product Search"
								icon={<TbShoppingBagSearch />}
							/>
							<MenuItem
								url="/product"
								name="Products"
								icon={<MdProductionQuantityLimits />}
							/>
						</>
					)}
					{[
						"engineering",
						"customer care",
						"sales",
						"technical manager",
					].includes(userRole.toLocaleLowerCase()) && (
						<MenuItem
							url="/product-detail"
							name="Product Search"
							icon={<TbShoppingBagSearch />}
						/>
					)}
					{userRole.toLocaleLowerCase() == "distributor" && (
						<MenuItem
							url="/product"
							name="Products"
							icon={<MdProductionQuantityLimits />}
						/>
					)}
					
					{[
						"customer care",
						"technical manager",
					].includes(userRole?.toLocaleLowerCase()) && (
						<MenuItem
							url="/repairs"
							name="Repairs"
							icon={<GiAutoRepair />}
						/>
					)}

					{userRole.toLocaleLowerCase() !== "distributor" && (
						<>
							<MenuItem
								url="/installation"
								name="Installation"
								icon={<GrInstall />}
							/>

							<MenuItem
								url="/maintainance"
								name="Maintainance"
								icon={<GiAutoRepair />}
							/>
						</>
					)}
					
					<MenuItem
						url="/logout"
						name="Logout"
						icon={<MdLogout />}
						onClick={logout}
					/>
				</div>
			</div>
		</div>
	);
}

export default Sidebar;
