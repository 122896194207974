import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import UserService from "../services/UserService";
import { PrimaryInput } from "./miniComponent/PrimaryInput";
import { PrimarySelect } from "./miniComponent/PrimarySelect";
import { PropagateLoader } from "react-spinners";
import { toast } from "react-toastify";

function UserModal(props) {
	let data = props.data;
	//if you want to use value instead of default value pass data into payload state without brace 
	//and do payload.value(role, location etc). better when an input change changes another input
	const [payload, setPayload] = useState({});
	const [loading, setLoading] = useState(false);

	const updatePayload = (data, key) => {
		const tempPayload = { ...payload };
		tempPayload[key] = data;
		setPayload(tempPayload);
	};

	

	const updateData = {
		id: props?.data?.id,
		firstName: props?.data?.firstName,
		lastName: props?.data?.lastName,
		role: props?.data?.role,
		isActive: props?.data?.isActive,
		email: props?.data?.email,
		location: props?.data?.location
	};

	const UpdateRecord = async () => {
		if (payload?.firstName) {
			updateData.firstName = payload?.firstName;
		}

		if (payload?.lastName) {
			updateData.lastName = payload?.lastName;
		}

		if (payload?.isActive) {
			updateData.isActive = payload?.isActive;
		}
		if (payload?.role) {
			updateData.role = payload?.role;
		}
		if (payload?.location) {
			updateData.location = payload?.location;
		}
		setLoading(true);
		try {
			const response = await UserService.UpdateUser(updateData);
			if (response?.status) {
				toast.success("Successfully created");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error("An error occured");
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	const CreateRecord = async () => {
		if (!payload.firstName) {
			alert("Firstname is Reqired");
			return;
		}

		if (!payload.lastName) {
			alert("Lastname is Reqired");
			return;
		}

		if (!payload.email) {
			alert("Email is Reqired");
			return;
		}

		if (!payload.role) {
			alert("Department/Role is required");
			return;
		}
		setLoading(true);
		try {
			const response = await UserService.CreateUser(payload);
			if (response?.status) {
				toast.success("Successfully created");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error("An error occured");
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">User</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal__main_container">
					<div className="card-grid-2">
						<PrimaryInput
							defaultValue={props?.data?.firstName}
							onChange={(e) => updatePayload(e.target.value, "firstName")}
							label="First name"
						/>
						<PrimaryInput
							defaultValue={props?.data?.lastName}
							onChange={(e) => updatePayload(e.target.value, "lastName")}
							label="Last name"
						/>
						<PrimaryInput
							defaultValue={props?.data?.email}
							onChange={(e) => updatePayload(e.target.value, "email")}
							label="Email"
							disabled={data != null}
						/>
						<PrimarySelect
							onChange={(e) => updatePayload(e.target.value, "role")}
							label="Department/Role"
							defaultValue={props?.data?.role}
							placeholder="Select Department"
							options={
								<>
									<option>{props?.data?.role || "Select Department"}</option>
									{[
										"Super Admin",
										"Sales",
										"Engineering",
										"Distributor",
										"Customer Care",
										"Store",
										"Technical Manager",
									].map((x) => (
										<option value={x}>{x}</option>
									))}
								</>
							}
						/>
						<PrimarySelect
							onChange={(e) => updatePayload(e.target.value, "location")}
							label="Branch"
							defaultValue={props?.data?.location}
							placeholder="Select Branch"
							options={
								<>
									<option>{props?.data?.location || "Select Branch"}</option>
									{[
										"Lagos",
										"Kano",
										"Akwa Ibom",
										"Edo"
									].map((x) => (
										<option value={x}>{x}</option>
									))}
								</>
							}
						/>
						{data != null && (
							<PrimarySelect
								onChange={(e) => updatePayload(e.target.value, "isActive")}
								label="Activate/Deactivate"
								value={props?.data?.isActive}
								placeholder="Toogle User"
								options={
									<>
										<option>
											{props?.data?.isActive ? "Active" : "Inactive"}
										</option>
										{[{id: true, label:"Active"}, {id: false, label:"Inactive"}].map((x) => (
											<option value={x.id}>{x.label}</option>
										))}
									</>
								}
							/>
						)}
					</div>
				</div>
			</Modal.Body>
			<div className="modal-foot">
				<button onClick={props.onHide} className="btn-form outline">
					Close
				</button>
				<button
					className="btn-form fill"
					onClick={() => {
						data == null ? CreateRecord() : UpdateRecord();
					}}
				>
					{loading ? (
						<PropagateLoader color={"white"} loading size={8} />
					) : (
						"Save"
					)}
				</button>
			</div>
		</Modal>
	);
}

export default UserModal;
