import React from "react";
import "../style/customCheckbox.css";

export default function CustomCheckbox({ label, checked, onChange }) {
	return (
		<label className="custom-checkbox">
			<input type="checkbox" checked={checked} onChange={onChange} />
			<span className={`checkmark ${checked ? "checked" : ""}`}></span>
			<span className="check-label">{label}</span>
		</label>
	);
}
