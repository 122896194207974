import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import People from "./pages/People";
import Product from "./pages/Product";
import ProductDetail from "./pages/ProductDetail";
import Repair from "./pages/Repair";
import SearchProduct from "./pages/SearchProduct";
import Installation from "./pages/Installation";
import CompleteReset from "./pages/CompleteReset";
import Maintainance from "./pages/Maintainance";

function RouteLinks() {
	return (
		<>
			<Routes>
				<Route exact={true} path="/" element={<Login />} />
				<Route
					exact={true}
					path="/forgot-password"
					element={<ForgotPassword />}
				/>
				{/* <Route
					exact={true}
					path="/password/complete-reset"
					element={<CompleteReset />}
				/> */}
				<Route
					exact={true}
					path="/password/reset/:code"
					element={<CompleteReset />}
				/>
				<Route exact={true} path="/dashboard" element={<Dashboard />} />
				<Route exact={true} path="/people" element={<People />} />
				<Route exact={true} path="/product" element={<Product />} />
				<Route
					exact={true}
					path="/product-detail/:id"
					element={<ProductDetail />}
				/>
				<Route exact={true} path="/repairs" element={<Repair />} />
				<Route
					exact={true}
					path="/product-detail"
					element={<SearchProduct />}
				/>
				<Route exact={true} path="/installation" element={<Installation />} />
				<Route exact={true} path="/maintainance" element={<Maintainance />} />
			</Routes>
		</>
	);
}

export default RouteLinks;
