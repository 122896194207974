import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UserService from '../services/UserService';
import { PropagateLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const [payload, setPayload] = useState({email: ""});
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

    const updatePayload=(e)=>{
        const name = e.target.name;
        setPayload(prevState => ({
          ...prevState,
          [name]: e.target.value
        })); 
    }

    const passwordReset = async() => {
      if(!payload.email){
          toast.error("email is required");
          return;
      }

      // console.log({payload});
      setLoading(true)
      try {
        const response = await UserService.PasswordReset(payload);
      if(response?.status){
        setLoading(false);
          toast.success("An email has been sent to you to verify your password");
          navigate('/')
      }else{
        setLoading(false);
        toast.error("An error occured")
      }
      } catch (error) {
        setLoading(false);
        toast.error(error?.message);
      }

      
  }


  return (
    <div className="main__container">
    <div className="image-container">
      <img src="/img-3.jpeg" alt="banner" />
    </div>
    <div className="login__container">
      <div className="login__input">
        <h2>Recover Password</h2>
        <input
          className="form-control input__field"
          type="email"
          name='email'
          placeholder="Enter Email"
          onChange={updatePayload}
        />
        
        <button className="my-btn" onClick={passwordReset}>
          {loading ? (
            <PropagateLoader color={"white"} loading={loading} size={8} />
          ) : (
            "Recover"
          )}
        </button>
        <Link to="/" style={{ textDecoration: "none" }}>
          <p className="password">Back to login</p>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword