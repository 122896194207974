import React, { useState } from "react";
import "./style/login.css";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import { PropagateLoader } from "react-spinners";
import { toast } from "react-toastify";

function Login() {
	const navigate = useNavigate();

	const [payload, setPayload] = useState({});
	const [loading, setLoading] = useState(false);

	const updatePayload = (data, key) => {
		const tempPayload = { ...payload };
		tempPayload[key] = data;
		setPayload(tempPayload);
	};

	const login = async () => {
		if (!payload.email) {
			toast.error("Please enter email");
			return;
		}
		if (!payload.password) {
			toast.error("Please enter password");
			return;
		}
		setLoading(true);
		try {
			const response = await UserService.userLogin(payload);
			var data = response.data;
			if (response?.status) {
				localStorage.setItem("role", data.role);
				localStorage.setItem("id", data.id);
				localStorage.setItem("token", data.token);
				localStorage.setItem("fullName", data.fullName);
				toast.success("Login Successful");
				if (data.role === "Super Admin") {
					navigate("/dashboard");
				} else if (
					data.role !== "Engineering" &&
					data.role !== "Customer Care" &&
					data.role !== "Sales" &&
					data.role !== "Technical Manager" &&
					data.role != "Store"
				) {
					navigate("/product");
				} else {
					navigate("/product-detail");
				}
			} else {
				setLoading(false);
				toast.error("Email or password is not correct");
			}
		} catch (error) {
			setLoading(false);
			toast.error(error?.message);
		}
	};

	return (
		<div className="main__container">
			<div className="image-container">
				<img src="/img-4.jpeg" alt="banner" />
			</div>
			<div className="login__container">
				<div className="login__input">
					<h2>Welcome Back!</h2>
					<input
						className="form-control input__field"
						type="text"
						placeholder="Email"
						onChange={(e) => updatePayload(e.target.value, "email")}
					/>
					<input
						className="form-control input__field"
						type="password"
						placeholder="Password"
						onChange={(e) => updatePayload(e.target.value, "password")}
					/>
					<button className="my-btn" onClick={login}>
						{loading ? (
							<PropagateLoader color={"white"} loading={loading} size={8} />
						) : (
							"Login"
						)}
					</button>
					<Link to="/forgot-password" style={{ textDecoration: "none" }}>
						<p className="password">Forget Password?</p>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Login;
