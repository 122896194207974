import APICall from "../utilities/APICall";

export default class UserService {
    static CreateUser = async (payload) => {
        return await APICall("/api/User/register", "POST", payload);
    };

    static UpdateUser = async (payload) => {
        return await APICall("/api/User/admin-update-user", "POST", payload);
    };

    static userLogin = async (payload) => {
      return await APICall("/api/User/login", "POST", payload);
    };

    static PasswordReset = async (payload) => {
        return await APICall("/api/User/reset/initiate", "POST", payload);
    }

    static PasswordResetComplete = async (payload) => {
        return await APICall("/api/User/reset/complete", "POST", payload);
    }

    static ListUserByRole = async (role, search, limit) => {
        return await APICall(`/api/User/list-users?role=${role}&search=${search}&offset=0&limit=${limit}`, "GET");
    }

}