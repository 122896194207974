import APICall from "../utilities/APICall";

export default class ProductService {
	static CreateProduct = async (payload) => {
		return await APICall("/api/Product/add-product", "POST", payload);
	};

	static UpdateProduct = async (payload) => {
		return await APICall("/api/Product/update", "PUT", payload);
	};

	static CreateRepair = async (payload) => {
		return await APICall("/api/Repair/add-repair", "POST", payload);
	};

	static CreateProductRepair = async (payload) => {
		return await APICall("/api/Repair/add-product-repair", "POST", payload);
	};

	static UpdateRepair = async (payload) => {
		return await APICall("/api/Repair/update", "PUT", payload);
	};

	static UpdateProductRepair = async (payload) => {
		return await APICall("/api/Repair/update-product-repair", "PUT", payload);
	};

	static getDashboardmetrics = async () => {
		return await APICall("/api/Dashboard/metrics", "GET");
	};

	static getProducts = async (search, limit, active) => {
		return await APICall(
			`/api/Product/products?search=${search}&offset=0&limit=${limit}&warantyFilter=${active}`,
			"GET"
		);
	};

	static getProduct = async (id) => {
		return await APICall(`/api/Product/product/${id}`, "GET");
	};

	static getRepairs = async (id, search, limit) => {
		return await APICall(
			`/api/Repair/repairs?productId=${id}&search=${search}&offset=0&limit=${limit}`,
			"GET"
		);
	};

	static getProductRepairs = async (id, search, limit, engineerId, status) => {
		return await APICall(
			`/api/Repair/product-repairs?productId=${id}&search=${search}&offset=0&limit=${limit}&engineerId=${engineerId}&status=${status}`,
			"GET"
		);
	};
	static getAllInstallations = async (
		search,
		limit,
		engineerId,
		status,
		isCompleted
	) => {
		return await APICall(
			`/api/Installation/installations?installationComplated=${isCompleted}&search=${search}&offset=0&limit=${limit}&engineerId=${engineerId}&status=${status}`,
			"GET"
		);
	};
	static CreateInstallation = async (payload) => {
		return await APICall("/api/Installation/add-installation", "POST", payload);
	};

	static UpdateInstallation = async (payload) => {
		return await APICall(
			"/api/Installation/update-installation",
			"PUT",
			payload
		);
	};

	static getAllMaintainances = async (
		search,
		limit,
		engineerId,
		status,
		isCompleted
	) => {
		return await APICall(
			`/api/Maintainance/maintainances?installationComplated=${isCompleted}&search=${search}&offset=0&limit=${limit}&engineerId=${engineerId}&status=${status}`,
			"GET"
		);
	};
	static CreateMaintainance = async (payload) => {
		return await APICall("/api/Maintainance/add-maintainance", "POST", payload);
	};

	static UpdateMaintainance = async (payload) => {
		return await APICall(
			"/api/Maintainance/update-maintainance",
			"PUT",
			payload
		);
	};
}
