import React from "react";

export const PrimaryInput = ({
	label,
	defaultValue,
	onChange,
	placeholder,
	disabled,
	type,
	readonly,
	isTextArea,
}) => {
	return (
		<div className="input-form">
			<label for="text" class="form-label labels">
				{label}
			</label>
			{isTextArea ? (
				<textarea
					className="form-controls text-area"
					defaultValue={defaultValue}
					type={type || "text"}
					placeholder={placeholder || `Enter ${label}`}
					onChange={onChange}
					disabled={disabled}
					readOnly={readonly}
				></textarea>
			) : (
				<input
					className="form-controls"
					defaultValue={defaultValue}
					type={type || "text"}
					placeholder={placeholder || `Enter ${label}`}
					onChange={onChange}
					disabled={disabled}
					readOnly={readonly}
				/>
			)}
		</div>
	);
};
