import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import UserService from '../services/UserService';
import { PropagateLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function CompleteReset() {
  const [payload, setPayload] = useState({code:'',password: "", confirmPassword: ""});
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const params = useParams();

    const updatePayload=(e)=>{
        const name = e.target.name;
        setPayload(prevState => ({
          ...prevState,
          [name]: e.target.value
        })); 
    }

    const passwordReset = async() => {
        payload.code = params.code;
    if(payload.password !== payload.confirmPassword){
        toast.error("Your passwords are not equal, Try again")
        return;
    }
    if(!payload.password || !payload.confirmPassword){
        toast.error("Password field is required")
        return;
    }
      // console.log({payload});
      setLoading(true)
      try {
        let resetPayload = {code: payload.code, newPassword: payload.password}
        const response = await UserService.PasswordResetComplete(resetPayload);
      if(response?.status){
        setLoading(false);
          toast.success(response?.message || "Password reset succesful");
          navigate('/')
      }else{
        setLoading(false);
        toast.error("An error occured")
      }
      } catch (error) {
        setLoading(false);
        toast.error(error?.message);
      }   
  }


  return (
    <div className="main__container">
    <div className="image-container">
      <img src="/img-2.jpeg" alt="banner" />
    </div>
    <div className="login__container">
      <div className="login__input">
      <h2>Set New Password</h2>
      {/* <input className='form-control input__field' type="code" placeholder="Enter Code" name="code" value={payload.code} onChange={updatePayload} required/> */}
      <input className='form-control input__field' type="password" placeholder="Password" name="password" value={payload.password} onChange={updatePayload} required/>
      <input className='form-control input__field' type="password" placeholder="Confirm Password" name="confirmPassword" value={payload.confirmPassword} onChange={updatePayload} required/>     
        <button className="my-btn" onClick={passwordReset}>
          {loading ? (
            <PropagateLoader color={"white"} loading={loading} size={8} />
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  </div>
  )
}

export default CompleteReset