import React from "react";
import { Link, useLocation } from "react-router-dom";

export const MenuItem = ({ url, name, icon, onClick }) => {
	const location = useLocation();
	const isActive = location.pathname.startsWith(url);
	return (
		<Link
			to={onClick ? "/" : url}
			className={`link ${isActive ? "active" : ""}`}
			onClick={onClick}
		>
			<div className="menu">
				{icon}
				<span className="item">{name}</span>
			</div>
		</Link>
	);
};
