import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProductService from "../services/ProductService";
import { PrimaryInput } from "./miniComponent/PrimaryInput";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";

function ProductModal(props) {
	const [payload, setPayload] = useState({});
	const [loading, setLoading] = useState(false);
	const [serialNumbers, setSerialNumbers] = useState(Array.from({ length: 1 }));
	const [serialNum, setSerialNum] = useState({ serialNumbers: serialNumbers });

	const userRole = localStorage.getItem("role");

	const updatePayload = (data, key) => {
		const tempPayload = { ...payload };
		tempPayload[key] = data;
		setPayload(tempPayload);
	};

	const handleSerialNumberChange = (index, value) => {
		const newSerialNumbers = [...serialNumbers];
		newSerialNumbers[index] = value;
		setSerialNumbers(newSerialNumbers);
		setSerialNum({ serialNumbers: newSerialNumbers });
	};

	const handleQuantityChange = (e) => {
		const quantity = Number(e.target.value);
		const newSerialNumbers = Array.from(
			{ length: quantity },
			(_, i) => serialNumbers[i] || ""
		);
		setSerialNumbers(newSerialNumbers);
	};

	let data = props.data;

	const updateData = {
		id: props?.data?.id,
		customerName: props?.data?.customerName,
		name: props?.data?.name,
		serialNumber: props?.data?.serialNumber,
		warantyDuration: props?.data?.warantyDuration,
		date: props?.data?.date,
	};

	const UpdateRecord = async () => {
		if (payload?.customerName) {
			updateData.customerName = payload?.customerName;
		}

		if (payload?.name) {
			updateData.name = payload?.name;
		}

		if (payload?.serialNumber) {
			updateData.serialNumber = payload?.serialNumber;
		}

		if (payload?.warantyDuration) {
			updateData.warantyDuration = payload?.warantyDuration;
		}
		if (payload?.date) {
			updateData.date = payload?.date;
		}
		setLoading(true);
		try {
			const response = await ProductService.UpdateProduct(updateData);
			if (response?.status) {
				toast.success("Successfully updated");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error("An error occured");
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	const CreateRecord = async () => {
		if (
			!payload.customerName &&
			userRole.toLocaleLowerCase() !== "distributor"
		) {
			toast.error("Customer name is Reqired");
			return;
		}

		if (!payload.name) {
			toast.error("Product name is Reqired");
			return;
		}

		if (!payload.warantyDuration) {
			toast.error("Waranty duration in month is Reqired");
			return;
		}

		if (!payload.date) {
			toast.error("Date is required");
			return;
		}

		if (serialNum === undefined || serialNum.length == 0) {
			toast.error("Serial number is required");
			return;
		}
		payload.serialNumbers = serialNum.serialNumbers;
		setLoading(true);
		try {
			const response = await ProductService.CreateProduct(payload);
			if (response?.status) {
				toast.success("Successfully added");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error("An error occured");
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		setSerialNum({ serialNumbers: serialNumbers });
	}, [serialNumbers]);

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Product</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal__main_container">
					<div className="card-grid-2">
						{userRole.toLowerCase() !== "distributor" && (
							<PrimaryInput
								defaultValue={props?.data?.customerName}
								onChange={(e) => updatePayload(e.target.value, "customerName")}
								label="Customer name"
							/>
						)}
						<PrimaryInput
							defaultValue={props?.data?.name}
							onChange={(e) => updatePayload(e.target.value, "name")}
							label="Product name"
						/>
						<PrimaryInput
							defaultValue={props?.data?.date}
							onChange={(e) => updatePayload(e.target.value, "date")}
							label="Date Sold"
							type="date"
						/>
						<PrimaryInput
							defaultValue={props?.data?.warantyDuration}
							onChange={(e) => updatePayload(e.target.value, "warantyDuration")}
							label="Waranty(Months)"
						/>
						{data == null && (
							<PrimaryInput
								defaultValue={props?.data?.quantity}
								onChange={handleQuantityChange}
								label="Quantity"
								type="number"
							/>
						)}
						{data != null ? (
							<PrimaryInput
								defaultValue={data.serialNumber}
								onChange={(e) => updatePayload(e.target.value, "serialNumber")}
								label={`Serial Number`}
							/>
						) : (
							<>
								{serialNumbers.map((x, i) => {
									return (
										<PrimaryInput
											key={i}
											defaultValue={x}
											onChange={(e) =>
												handleSerialNumberChange(i, e.target.value)
											}
											label={`Serial Number ${i + 1}`}
										/>
									);
								})}
							</>
						)}
					</div>
				</div>
			</Modal.Body>
			{/* <Modal.Footer> */}
			<div className="modal-foot">
				<button onClick={props.onHide} className="btn-form outline">
					Close
				</button>
				<button
					className="btn-form fill"
					onClick={() => {
						data == null ? CreateRecord() : UpdateRecord();
					}}
				>
					{loading ? (
						<PropagateLoader color={"white"} loading size={8} />
					) : (
						"Save"
					)}
				</button>
			</div>
			{/* </Modal.Footer> */}
		</Modal>
	);
}

export default ProductModal;
