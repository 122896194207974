import React, { useEffect, useState } from "react";
import Sidebar from "../component/Sidebar";
import "./style/people.css";
import { Table } from "react-bootstrap";
import UserService from "../services/UserService";
import UserModal from "../component/UserModal";
import { TableHead } from "../component/miniComponent/TableHead";
import { TableData } from "../component/miniComponent/TableData";
import { toast } from "react-toastify";
import { TableSkeleton } from "../component/miniComponent/TableSkeleton";
import { NoData } from "../component/miniComponent/NoData";
import { FaPeopleRoof } from "react-icons/fa6";

function People() {
	const [people, setPeople] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [limit, setLimit] = useState(20);
	const [loading, setLoading] = useState(false);

	const listUserByRole = async (role, search, limit) => {
		setLoading(true);
		try {
			const users = await UserService.ListUserByRole(role, search, limit);
			if (users?.status) {
				setLoading(false);
				setPeople(users?.data?.value || []);
				return;
			}
			toast.error(users?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		listUserByRole("all", searchQuery, limit);
	}, [searchQuery, limit]);

	return (
		<>
			<div className="container__wrapper">
				<Sidebar />
				<div className="section__container">
					<div className="header__title">
						<h3>People</h3>
					</div>
					<div className="top-style">
						<button
							className="action-btn"
							onClick={() => {
								setRecordToEdit(null);
								setModalShow(true);
							}}
						>
							Add User
						</button>
						<div className="search-box">
							<input
								className="form-control"
								type="text"
								placeholder="search user"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					</div>
					<div className={`table-responsive ${people?.length > 0 ? "" : "people-head"}`}>
						<Table>
							<thead>
								<tr className="table-head-row">
									<TableHead>Fullname</TableHead>
									<TableHead>Email</TableHead>
									<TableHead>Role</TableHead>
									<TableHead>Location</TableHead>
									<TableHead>Active</TableHead>
									<TableHead>Action</TableHead>
								</tr>
							</thead>

							<tbody>
								{loading ? (
									<TableSkeleton col={6} row={6} />
								) : (
									<>
										{people.length > 0 ? (
											<>
												{people.map((x) => {
													return (
														<tr className="tbody-row">
															<TableData>{x.fullName}</TableData>
															<TableData>{x.email}</TableData>
															<TableData>{x.role}</TableData>
															<TableData>{x.location}</TableData>
															<TableData>
																{x.isActive ? "True" : "False"}
															</TableData>
															<TableData>
																<button
																	className="table-btn"
																	onClick={() => {
																		setRecordToEdit(x);
																		setModalShow(true);
																	}}
																>
																	View User
																</button>
															</TableData>
														</tr>
													);
												})}
											</>
										) : (
											<NoData
												text="There is no data to show yet"
												icon={<FaPeopleRoof />}
											/>
										)}
									</>
								)}
							</tbody>
						</Table>
					</div>
					<div className="d-flex justify-content-between align-items-center mt-4">
						<div className="page-count">
							Showing {people?.length || 0} items on this page
						</div>
						<button
							className="view-more"
							onClick={() => setLimit((prev) => prev + 5)}
						>
							View More
						</button>
					</div>
				</div>
			</div>
			{modalShow && (
				<UserModal
					show={modalShow}
					onHide={() => setModalShow(false)}
					data={recordToEdit}
				/>
			)}
		</>
	);
}

export default People;
